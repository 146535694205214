import { begonia_post_form, nuwa_post_form, nuwa_post, begonia_post } from './http'
import store from '@/store/index'

export const loginByWechat = (data) => {
    return begonia_post_form({
        path: '/user/loginByWechat',
        data,
    })
}

export const getCaptcha = (data) => {
    return begonia_post_form({
        path: '/user/getCaptcha',
        data,
    })
}

export const loginByCaptcha = (data) => {
    return begonia_post_form({
        path: '/user/loginByCaptcha',
        data,
    })
}

export const getWxMpLoginQRCode = (data) => {
    return begonia_post_form({
        path: '/user/getWxMpLoginQRCode',
        data,
    })
}

export const userLogin = (data) => {
    return begonia_post({
        path: '/user/login',
        data,
        loading: {
            statue: false,
        },
    })
}

export const getOAuthTpTicket = (data) => {
    return begonia_post({
        path: '/user/getOAuthTpTicket',
        data,
    })
}

export const loginByTpTicket = (data) => {
    return begonia_post_form({
        path: '/user/loginByTpTicket',
        data,
        loading: {
            statue: false,
        },
    })
}

export const userInfoMod = (nickname) => {
    const { userInfo } = store.state.user
    return begonia_post_form({
        path: '/user/mod',
        data: {
            userId: userInfo.id,
            nickname,
        },
    })
}

export const userTelMod = (data) => {
    return begonia_post_form({
        path: '/user/bindTel',
        data: data,
        userToken: data.token,
    })
}

export const userOrders = () => {
    const { userInfo } = store.state.user
    return begonia_post_form({
        path: '/trade/userOrders',
        data: {
            userId: userInfo.id,
        },
    })
}

// 已购课程 和 课程最后观看记录
export const getUserAttendLast = (packageIds) => {
    const { userInfo } = store.state.user
    return nuwa_post({
        path: '/course/getUserAttendLast',
        data: {
            userId: userInfo.id,
            packageIds,
        },
    })
}

export const coursePackageUserList = (data) => {
    const { userInfo } = store.state.user
    return nuwa_post_form({
        path: '/coursePackageUser/list',
        data: { userId: userInfo.id, ...data },
    })
}

export const getPaidStatus = (packageNo) => {
    const { userInfo } = store.state.user

    return begonia_post_form({
        path: '/coursePackage/getPaidStatus',
        data: {
            packageNo,
            userId: userInfo.id,
        },
    })
}

// 短链接 获取支付宝地址
export const fetchOrder = function (data) {
    return begonia_post({
        path: '/trade/fetchOrder',
        data,
    })
}
export const shortText = function (sk) {
    return begonia_post_form({
        path: '/shortText/sk/' + sk,
    })
}

export const getStages = (packageNo) => {
    const params = {
        packageNo,
    }
    return nuwa_post_form({
        path: '/courseStage/list',
        data: params,
    })
}

// types= PAID_COURSE,MOCK_EXAM,APP_VOCAB （默认值）EXAM_VIP 热词 VIP
// 不传types 默认 PAID_COURSE,MOCK_EXAM,APP_VOCAB
// 获取用户是否购买catti 课程
export const getUserPaid = () => {
    const { userInfo } = store.state.user
    return begonia_post_form({
        path: '/user/paid',
        data: {
            userId: userInfo.id,
        },
        loading: { statue: false },
    })
}

// 成绩查询
export const getExamResultSubjects = () => {
    return begonia_post_form({
        path: '/officialExamResult/subjects',
        data: {},
    })
}
export const getExamResult = (data) => {
    return begonia_post_form({
        path: '/officialExamResult/query',
        data,
        isCatch: true,
    })
}
export const getKaptchaUuid = () => {
    const str = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
    return `${str}${+new Date()}`
}
export const subscribeExamResult = (data) => {
    return begonia_post_form({
        path: '/officialExamResult/subscribe',
        data,
        isCatch: true,
    })
}

// 用户操作埋点
export const uploadUserForm = (data) => {
    return begonia_post({
        path: '/plugin/uploadForm',
        data,
        loading: {
            statue: false,
        },
        isCatch: true,
    })
}

// 微信支付
export const wechatPay = (params, cb) => {
    const onBridgeReady = () => {
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', params, cb)
    }
    if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
    } else {
        onBridgeReady()
    }
}

// 授权登录
export const loginByAuthTicket = (data) => {
    return begonia_post_form({
        path: '/user/loginByAuthTicket',
        data,
        loading: {
            statue: false,
        },
        isCatch: true,
    })
}

// 身份认证
export const getAgreementStates = (data) => {
    const { userInfo } = store.state.user
    return begonia_post({
        path: '/trade/getAgreementStates',
        data: { userId: userInfo.id, ...data },
        loading: {
            statue: false,
        },
        isCatch: true,
    })
}
export const signAgreement = (data) => {
    return begonia_post_form({
        path: '/trade/signAgreement',
        data: {
            agree: true,
            ...data,
        },
    })
}

// 关注公众号状态
export const getMpSubscribeStatus = (data) => {
    return begonia_post_form({
        path: '/wxMp/subscribeStatus/' + data.appid,
        data,
        isCatch: true,
        loading: { status: false },
    })
}
